<template>
  <div class="not-found bg--color-02">
    <div class="not-found__block-side">
      <div
        class="
          not-found__block-side-container
          bg--color-01
          bor--radius-12
          p-24
          sha--03
        "
      >
        <div class="not-found__img mb-24">
          <img
            src="@/assets/img/not-found/not-found.svg"
            alt="not found image"
          >
        </div>
        <div class="not-found__text flx flx--col">
          <span class="txt--bold txt--color-04 txt--26px txt--height-34px mb-8">
            {{ $t('notFound.title') }}
          </span>
          <p class="txt--16px txt--height-20px txt--color-04">
            {{ $t('notFound.description') }}
          </p>
        </div>
        <div class="not-found__btn mt-26">
          <exc-button size="s" full-width :to="localePath('/')">
            <div class="not-found__btn-content">
              <span>
                {{ $t('notFound.button') }}
              </span>

              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.1121 11.0467C16.1121 10.8731 16.0448 10.7212 15.9102 10.591L11.8477 6.52848C11.774 6.45904 11.698 6.40913 11.6199 6.37874C11.5461 6.34402 11.4701 6.32666 11.392 6.32666C11.2184 6.32666 11.073 6.38308 10.9558 6.49593C10.843 6.60878 10.7865 6.74984 10.7865 6.91911C10.7865 7.00157 10.8017 7.08187 10.8321 7.15999C10.8625 7.23812 10.9037 7.30322 10.9558 7.35531L12.336 8.75505L14.7058 10.9165L14.8816 10.5584L12.8178 10.4347H5.18758C5.00529 10.4347 4.85555 10.4933 4.73836 10.6105C4.62552 10.7234 4.56909 10.8688 4.56909 11.0467C4.56909 11.229 4.62552 11.3787 4.73836 11.4959C4.85555 11.6088 5.00529 11.6652 5.18758 11.6652H12.8178L14.8816 11.535L14.7058 11.1899L12.336 13.3384L10.9558 14.7446C10.9037 14.7967 10.8625 14.8618 10.8321 14.9399C10.8017 15.0137 10.7865 15.094 10.7865 15.1808C10.7865 15.3501 10.843 15.4912 10.9558 15.604C11.073 15.7169 11.2184 15.7733 11.392 15.7733C11.5526 15.7733 11.698 15.7082 11.8282 15.578L15.9102 11.509C16.0448 11.3787 16.1121 11.2247 16.1121 11.0467Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </exc-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExcButton } from '@/components/common'

export default {
  name: 'NotFound',
  components: {
    ExcButton,
  },
  data () {
    return {
      title: 'Page not found',
    }
  },
  head () {
    return {
      title: this.title,
    }
  },
}
</script>

<style lang="scss" scoped>
.not-found {
  overflow-x: hidden;
  display: flex;
  min-height: calc(100vh - #{$h-Header});
  flex: 1;
  align-items: center;

  &__block-side {
    flex: 1;
    display: flex;
    z-index: 2;
  }

  &__block-side-container {
    position: relative;
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: center;
    max-width: toRem(362px);
    height: fit-content;
    margin: toRem(20px) auto;
  }

  &__img {
    img {
      width: 100%;
    }
  }

  &__btn-content {
    display: flex;
    align-items: center;
    gap: toRem(4px);
  }
}
</style>
